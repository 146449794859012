<template>
   <div class="developer-request main">
     <div class="title">파트너 개발자 신청</div>
     <div class="content">
        <div class="content-title">정산계좌정보</div>
        <div class="content-form">
          <div class="form-item">
            <div class="form-label main">은행명 <span class="required">*</span></div>
            <div class="form-input">
              <lp-drop-down :value.sync="value.bank_info.bank" 
              placeholder="은행명"
              :options="bankOptions" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-label main">예금주 <span class="required">*</span></div>
            <div class="form-input">
              <lp-text :value.sync="value.bank_info.name" placeholder="예금주" :maxLength="10" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-label main">계좌번호 <span class="required">*</span></div>
            <div class="form-input">
              <lp-text :value.sync="value.bank_info.account_num" placeholder="계좌번호" :maxLength="20" />
            </div>
          </div>
          <div class="form-item">
            <div class="form-label main">약관 <span class="required">*</span></div>
            <div class="form-input flex-align">
              <lp-check-bool :checkedVal.sync="value.agree"
              :labelStyle="labelStyle"
              label="개인정보 처리 위탁에 동의"></lp-check-bool>
              <div class="sub3 body5">(필수)</div>
              <a class="sub3 size-12 text-underline margin-left-8 unselect" href="/privacy" target="_blank">약관보기</a>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="button is-primary" @click="submit">파트너 개발자 신청</button>
        </div>
     </div>
   </div>
</template>
<script>
import LpDropDown from '../LpDropDown.vue'
import LpText from '../component/LpText.vue'
import LpCheckBool from "../module/LpCheckBool";
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin'
export default {
   name: 'DeveloperRequest',
   components: {
    LpDropDown,
    LpText,
    LpCheckBool
   },
   mixins: [
    PartnerAPIMixin
   ],
   props: {
     
   },
   data() {
     return {
      bankOptions: [
        {
          "value": "농협중앙회",
          "label": "농협중앙회"
        },
        {
          "value": "단위농협",
          "label": "단위농협"
        },
        {
          "value": "축협중앙회",
          "label": "축협중앙회"
        },
        {
          "value": "우리은행",
          "label": "우리은행"
        },
        {
          "value": "상업은행",
          "label": "상업은행"
        },
        {
          "value": "SC제일은행",
          "label": "SC제일은행"
        },
        {
          "value": "한일은행",
          "label": "한일은행"
        },
        {
          "value": "서울은행",
          "label": "서울은행"
        },
        {
          "value": "신한은행",
          "label": "신한은행"
        },
        {
          "value": "한국씨티은행",
          "label": "한국씨티은행"
        },
        {
          "value": "대구은행",
          "label": "대구은행"
        },
        {
          "value": "부산은행",
          "label": "부산은행"
        },
        {
          "value": "광주은행",
          "label": "광주은행"
        },
        {
          "value": "제주은행",
          "label": "제주은행"
        },
        {
          "value": "전북은행",
          "label": "전북은행"
        },
        {
          "value": "강원은행",
          "label": "강원은행"
        },
        {
          "value": "경남은행",
          "label": "경남은행"
        },
        {
          "value": "비씨카드",
          "label": "비씨카드"
        },
        {
          "value": "새마을금고",
          "label": "새마을금고"
        },
        {
          "value": "신용협동조합중앙회",
          "label": "신용협동조합중앙회"
        },
        {
          "value": "상호저축은행",
          "label": "상호저축은행"
        },
        {
          "value": "홍콩상하이은행",
          "label": "홍콩상하이은행"
        },
        {
          "value": "도이치은행",
          "label": "도이치은행"
        },
        {
          "value": "ABN암로",
          "label": "ABN암로"
        },
        {
          "value": "JP모건",
          "label": "JP모건"
        },
        {
          "value": "미쓰비시도쿄은행",
          "label": "미쓰비시도쿄은행"
        },
        {
          "value": "BOA(Bank of America)",
          "label": "BOA(Bank of America)"
        },
        {
          "value": "산림조합",
          "label": "산림조합"
        },
        {
          "value": "신안상호저축은행",
          "label": "신안상호저축은행"
        },
        {
          "value": "우체국",
          "label": "우체국"
        },
        {
          "value": "하나은행",
          "label": "하나은행"
        },
        {
          "value": "평화은행",
          "label": "평화은행"
        },
        {
          "value": "케이뱅크",
          "label": "케이뱅크"
        },
        {
          "value": "카카오뱅크",
          "label": "카카오뱅크"
        },
        {
          "value": "한국산업은행",
          "label": "한국산업은행"
        },
        {
          "value": "기업은행",
          "label": "기업은행"
        },
        {
          "value": "국민은행",
          "label": "국민은행"
        },
        {
          "value": "수협중앙회",
          "label": "수협중앙회"
        },
        {
          "value": "유안타증권",
          "label": "유안타증권"
        },
        {
          "value": "현대증권",
          "label": "현대증권"
        },
        {
          "value": "미래에셋증권",
          "label": "미래에셋증권"
        },
        {
          "value": "한국투자증권",
          "label": "한국투자증권"
        },
        {
          "value": "우리투자증권",
          "label": "우리투자증권"
        },
        {
          "value": "하이투자증권",
          "label": "하이투자증권"
        },
        {
          "value": "HMC투자증권",
          "label": "HMC투자증권"
        },
        {
          "value": "SK증권",
          "label": "SK증권"
        },
        {
          "value": "대신증권",
          "label": "대신증권"
        },
        {
          "value": "하나대투증권",
          "label": "하나대투증권"
        },
        {
          "value": "굿모닝신한증권",
          "label": "굿모닝신한증권"
        },
        {
          "value": "동부증권",
          "label": "동부증권"
        },
        {
          "value": "유진투자증권",
          "label": "유진투자증권"
        },
        {
          "value": "메리츠증권",
          "label": "메리츠증권"
        },
        {
          "value": "신영증권",
          "label": "신영증권"
        },
        {
          "value": "대우증권",
          "label": "대우증권"
        },
        {
          "value": "삼성증권",
          "label": "삼성증권"
        },
        {
          "value": "교보증권",
          "label": "교보증권"
        },
        {
          "value": "키움증권",
          "label": "키움증권"
        },
        {
          "value": "이트레이드",
          "label": "이트레이드"
        },
        {
          "value": "솔로몬증권",
          "label": "솔로몬증권"
        },
        {
          "value": "한화증권",
          "label": "한화증권"
        },
        {
          "value": "NH증권",
          "label": "NH증권"
        },
        {
          "value": "부국증권",
          "label": "부국증권"
        },
        {
          "value": "LIG증권",
          "label": "LIG증권"
        }
      ],
       value: {
        agree: false,
        bank_info: {
          name: '',
          bank: '',
          account_num: ''
        }
       },
       labelStyle: {
        fontSize: '13px',
        color: '#333',
        lineHeight: '20px',
        cursor: 'pointer',
        'user-select': 'none'

       }
     }
   },
   computed: {
     
   },
   watch: {
     
   },
   mounted() {
     
   },
   methods: {
    isValid() {
      let result = true;
      let msg = '';
      if(this.value.agree === false) {
        msg = '약관에 동의해주세요.';
      }
      if(this.value.bank_info.account_num === '') {
        msg = '계좌번호를 입력해주세요.';
      }
      if(this.value.bank_info.name === '') {
        msg = '예금주를 입력해주세요.';
      }
      if(this.value.bank_info.bank === '') {
        msg = '은행을 선택해주세요.';
      }
      if(msg !== '') {
        this.toast(msg);
        result = false;
      }
      return result;
    },
     submit() {
      if(this.isValid()) {
        this.request.partner.post('developer_request', this.value).then(() => {
          let user = this.user;
          if(!user.partner) {
            user.partner = {
              request_status: 1
            };
          } else {
            user.partner.request_status = 1;
          }
          this.$store.commit('setUser', user);

          this.$router.push('/developer_request_done');
        })
      }
     }
   }
};
</script>
<style lang='stylus' scoped>
.developer-request
  padding 40px 0 120px 0
  .title
    font-size 24px
    font-weight 700
    color $main
  .content
    margin-top 24px
    background-color white
    padding 40px
    border-radius 12px
    .content-title
      font-size 18px
      font-weight 700
  
  .content-form
    display flex
    flex-direction column

  .form-item
    display flex
    align-items center    
    padding 24px 0
  .form-label
    font-size 15px
    font-weight 500
    width 172px
    .required
      color #FF3B3B
  .form-input
    flex 1

.buttons
  border-top 1px solid #e3e3e3
  padding-top 40px
  display flex
  justify-content center
.button
  font-size 16px
  font-weight 500
  width 240px
  height 52px

@media (max-width: 600px)
  .developer-request
    padding 24px 0
    .title
      font-size 18px
  .developer-request .content .content-title
    font-size 16px
  .form-item
    flex-direction column
    gap 8px
    .form-label
      width 100%
    .form-input
      width 100%
</style>